import styled from 'styled-components';
import { media } from '../../ui/utils/media';

export const HeaderGap = styled.div`
	margin-top: ${({ theme }) => theme.headerHeight.mobile}px;
	${media.tabletPortraitUp} {
		margin-top: ${({ theme }) => theme.headerHeight.tabletPortrait}px;
	}
	${media.tabletLandscapeUp} {
		margin-top: ${({ theme }) => theme.headerHeight.tabletLandscape}px;
	}
	${media.desktopUp} {
		margin-top: ${({ theme }) => theme.headerHeight.desktop}px;
	}
	${media.desktopLargeUp} {
		margin-top: ${({ theme }) => theme.headerHeight.desktopLarge}px;
	}
	${media.desktopXLargeUp} {
		margin-top: ${({ theme }) => theme.headerHeight.desktopXLarge}px;
	}
`;

import styled from 'styled-components';
import { media } from '../../ui/utils/media';

export const TitleContainer = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: 64px;
`;

export type TextAlignment = 'left' | 'right' | 'center';

export const TitleWrapper = styled.div<{ alignment: TextAlignment }>`
	display: flex;
	justify-content: ${({ alignment }) => alignment};
	align-items: ${({ alignment }) => alignment};
	width: 100%;
	padding: 0;

	span:nth-child(odd) {
		::after {
			content: '. ';
		}
		color: ${({ theme }) => theme.colors.white};
	}

	span:nth-child(even) {
		::after {
			content: '. ';
		}
		color: ${({ theme }) => theme.colors.tealPlus60};
	}

	span:last-child {
		::after {
			content: '';
		}
	}

	${media.tabletLandscapeUp} {
		max-width: 1440px;
		padding: 0px 96px;
	}
`;

export const TextContainer = styled.div<{ alignment: TextAlignment }>`
	text-align: ${({ alignment }) => alignment};
	width: 100%;

	${media.tabletLandscapeUp} {
		width: 60%;
	}

	${media.desktopLargeUp} {
		width: 80%;
	}
`;

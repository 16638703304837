import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { ImagePreset, Movie, Series, Category } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { ImageComponent, ImageComponentContainer } from './ImageComponent';
import { GRID_GUTTER_WIDTH } from '../styles/variables';
import { Highlight } from './Highlight';
import MediaPoster from '../models/strapi/MediaPoster';

const PosterContainer = styled.div`
	cursor: pointer;
	position: relative;
	flex: 0 0 auto;
	margin-bottom: ${GRID_GUTTER_WIDTH};

	.image {
		width: 100%;
		height: 0;
		padding-bottom: 150%;
		background-size: 100% 100%;
		border-radius: 6px;
	}
`;

type PosterProps = {
	movie?: Movie;
	strapiPoster?: MediaPoster;
	category?: Category;
	enabled?: boolean;
};

const Poster: React.FC<PosterProps> = ({ movie, strapiPoster, category, enabled }) => {
	const router = useRouter();

	let imageUrl = null;

	const presets = [ImagePreset.POSTER_SMALL];
	if (strapiPoster) {
		imageUrl = strapiPoster.imagePoster.data.attributes.url;
	} else if (movie) {
		imageUrl = movie.getPosterImageUrl({
			presets,
		});
	} else if (category) {
		imageUrl = category.getPosterImageUrl({
			presets,
		});
	}

	return (
		<PosterContainer
			role={'presentation'}
			className={'poster'}
			onClick={(e) => {
				e.preventDefault();

				if (enabled === false || !!strapiPoster) return;

				if (movie) {
					if (movie instanceof Series) {
						router.push(`/series/${movie.id}`);
					} else {
						router.push(`/movie/${movie.id}`);
					}
				} else if (category) {
					router.push(`/category/${category.id}`);
				}
			}}
		>
			<ImageComponentContainer>
				{strapiPoster ? <ImageComponent className={'image'} isStrapiImage images={[imageUrl]} /> : <ImageComponent className={'image'} images={[imageUrl]} />}
			</ImageComponentContainer>
			<Highlight />
		</PosterContainer>
	);
};

export default Poster;

import React, { useCallback, useMemo } from 'react';
import { ModuleTemplate, ModuleType } from '@vodafoneis/sjonvarpskjarni-js-lib';
import Poster from '../components/Poster';
import { MediaComponent } from '../components/MediaComponent';
import { MediaComponentType } from '../models/MediaComponentType';
import { CarouselColumn } from '../components/CarouselColumn';

const { CONTENT } = MediaComponentType;
const { M2, M3, M4, POSTER, POSTER_LARGE, HOPSTER } = ModuleType;
const { OVERLAY_ICON } = ModuleTemplate;

type ColumnConfig = {
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
};

type ContentGridRendererProps = {
	moduleType?: ModuleType;
	moduleTemplate?: ModuleTemplate;
	mediaComponentType?: MediaComponentType;
	columnConfig?: ColumnConfig;
};

export default function useContentGridRenderer({ moduleType, moduleTemplate, mediaComponentType, columnConfig }: ContentGridRendererProps) {
	moduleType = moduleType || POSTER; // eslint-disable-line no-param-reassign
	moduleTemplate = moduleTemplate || OVERLAY_ICON; // eslint-disable-line no-param-reassign
	mediaComponentType = mediaComponentType || CONTENT; // eslint-disable-line no-param-reassign

	const calculatedColumnConfig = useMemo(() => {
		if (columnConfig) return columnConfig;

		const defaultColumnConfig = {
			xs: 12,
			md: 6,
		};

		switch (moduleType) {
			case M2:
				return defaultColumnConfig;
			case M3:
				return {
					...defaultColumnConfig,
					lg: 4,
				};
			case M4:
				return {
					...defaultColumnConfig,
					lg: 3,
				};
			case HOPSTER:
				return {
					xs: 6,
					sm: 4,
					md: 3,
					lg: 2,
				};
			case POSTER_LARGE:
				return {
					xs: 4,
					sm: 3,
				};
			case POSTER:
			default:
				return {
					xs: 4,
					sm: 3,
					lg: 2,
				};
		}
	}, [columnConfig, moduleType]);

	const mediaComponentSize = useMemo(() => {
		switch (moduleType) {
			case M2:
				return M2;
			case M3:
				return M3;
			case M4:
				return M4;
			case HOPSTER:
				return HOPSTER;
			case POSTER:
			default:
				return POSTER;
		}
	}, [moduleType]);

	return useCallback(
		(item, clickEnabled) => {
			// eslint-disable-next-line no-param-reassign
			if (clickEnabled === undefined) clickEnabled = true;

			let isStrapi;

			if (item.image && item.imagePoster) isStrapi = item.image;

			if (mediaComponentSize === POSTER) {
				if (item.image && item.imagePoster) isStrapi = item.imagePoster;
				return (
					<CarouselColumn key={item.id} {...calculatedColumnConfig}>
						{isStrapi ? <Poster strapiPoster={item} enabled={clickEnabled} /> : <Poster movie={item} enabled={clickEnabled} />}
					</CarouselColumn>
				);
			}

			return (
				<CarouselColumn key={item.id} {...calculatedColumnConfig}>
					<MediaComponent item={item} size={mediaComponentSize} type={mediaComponentType} template={moduleTemplate} enabled={clickEnabled} isStrapi={isStrapi} />
				</CarouselColumn>
			);
		},
		[calculatedColumnConfig, mediaComponentSize, mediaComponentType, moduleTemplate]
	);
}

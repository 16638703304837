import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useEmblaCarousel from 'embla-carousel-react';
import { useTheme } from 'styled-components';
import { useWindowSize } from 'react-use';
import { H2, H5 } from '../../ui/core/Typography/typography.styles';
import { MainBuyLinkButton } from '../../ui/core/Buttons/Buttons';
import { processCmsMediaUrl } from '../../utils/urls';
import {
	PackageBodyContainer,
	PackageContainer,
	PackageHeadContainer,
	PackageImageContainer,
	PackageItem,
	PackageItemBody,
	PackageTextContainer,
	PackageTitleContainer,
	PriceText,
	BodyTextColor,
	PackageFooterContainer,
	PackageImage,
	IntroTexColor,
	PackageCarousel,
	PackageCarouselContent,
	Slide,
	PriceContainer,
	ButtonContainer,
} from './Package.styles';
import { Icon, ICON_TYPE } from '../IconComponent/IconComponent';
import { NextBtn, PrevBtn } from '../EmblaCarousel/EmblaActionButtons.styles';
import Packages from '../../models/strapi/Package';
import { useDevicetype } from '../../hooks/useDeviceType';

type CarouselProps = {
	disabled?: boolean;
	packages?: Packages[];
};

export const PackagesCarousel: React.FC<CarouselProps> = ({ disabled, packages }) => {
	const { screenLayout } = useTheme();
	const deviceType = useDevicetype();

	const { t } = useTranslation();
	const [viewportRef, embla] = useEmblaCarousel({
		skipSnaps: false,
		align: 'start',
		containScroll: 'trimSnaps',
		breakpoints: {
			[`(min-width: ${screenLayout.desktopLarge})`]: { align: 'center' },
		},
	});
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

	const onSelect = useCallback(() => {
		if (!embla) return;
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla]);

	useEffect(() => {
		if (!embla) return;
		onSelect();
		embla.on('select', onSelect);
		embla.on('resize', onSelect);
	}, [embla, onSelect]);

	const renderPackageItems = () => {
		return packages.map((item) => renderPackageItem(item));
	};

	const renderPackageItem = (item: Packages) => {
		return (
			<Slide key={item.id}>
				<PackageItem>
					<PackageImageContainer>
						<PackageImage src={processCmsMediaUrl(deviceType === 'mobile', item.image, item.multimedia)} />
					</PackageImageContainer>
					<PackageItemBody>
						<PackageTitleContainer>
							<H5>{item.title}</H5>
						</PackageTitleContainer>
						<PackageTextContainer>
							<BodyTextColor>{item.description}</BodyTextColor>
						</PackageTextContainer>
					</PackageItemBody>
					<PackageFooterContainer>
						<PriceContainer>
							<PriceText isBold>{item.price}</PriceText>
							<PriceText>{item.perTime}</PriceText>
						</PriceContainer>
						<ButtonContainer>
							<MainBuyLinkButton text={t('Navigation.Subscriptions')} url={item.url} />
						</ButtonContainer>
					</PackageFooterContainer>
				</PackageItem>
			</Slide>
		);
	};

	return (
		<>
			{!disabled ? (
				<PackageContainer>
					<PackageHeadContainer>
						<H2>{t('Packages.Title')}</H2>
						<IntroTexColor>{t('Packages.Text')}</IntroTexColor>
					</PackageHeadContainer>
					<PackageBodyContainer>
						<PrevBtn onClick={scrollPrev} disabled={!prevBtnEnabled}>
							<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
						</PrevBtn>
						<NextBtn onClick={scrollNext} disabled={!nextBtnEnabled}>
							<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
						</NextBtn>
						<PackageCarousel ref={viewportRef}>
							<PackageCarouselContent>{renderPackageItems()}</PackageCarouselContent>
						</PackageCarousel>
					</PackageBodyContainer>
				</PackageContainer>
			) : (
				<> </>
			)}
		</>
	);
};

import ContentSection from './ContentSection/ContentSection';
import MetaData from './ContentSection/MetaData';
import HeroBanner from './ContentSection/HeroBanner';
import ContentSectionLocalizations from './ContentSection/ContentSectionLocalizations';

export default class PageContent {
	metadata: MetaData;
	heroBanner?: HeroBanner;
	contentSections: ContentSection[];
	localizations: ContentSectionLocalizations;

	constructor(data: object) {
		Object.assign(this, data);
	}

	getPageMetaDataByLocale(locale: string) {
		if (locale === 'en') {
			if (this.localizations?.data?.find((l) => l?.attributes?.locale === locale)) {
				return this.localizations?.data?.find((l) => l?.attributes?.locale === locale)?.attributes?.metadata;
			}
			return this.metadata;
		}
		return this.metadata;
	}

	getPageHeroBannerByLocale(locale: string) {
		if (locale === 'en') {
			if (this.localizations?.data?.find((l) => l?.attributes?.locale === locale)) {
				return this.localizations?.data?.find((l) => l?.attributes?.locale === locale)?.attributes?.heroBanner;
			}
			return this.heroBanner;
		}
		return this.heroBanner;
	}

	getContentSectionByLocale(locale: string) {
		if (locale === 'en') {
			if (this.localizations?.data?.find((l) => l?.attributes?.locale === locale)) {
				return this.localizations?.data?.find((l) => l?.attributes?.locale === locale)?.attributes?.contentSections;
			}
			return this.contentSections;
		}

		return this.contentSections;
	}
}

import Image from 'next/image';
import React, { useEffect, useCallback, useState, FC } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { useHasMounted } from '../../hooks/useHasMounted';
import { TagTextSmall, BodyText, H1 } from '../../ui/core/Typography/typography.styles';
import { processCmsImageUrl } from '../../utils/urls';
import { Icon, ICON_TYPE } from '../IconComponent/IconComponent';
import { CircleNextBtn, CirclePrevBtn, EmblaDotButtonBlue } from '../EmblaCarousel/EmblaActionButtons.styles';
import {
	GalleryContainer,
	BackgroundMask,
	GallerySection,
	InfoContainer,
	HeadText,
	ImageCarouselSection,
	CarouselSection,
	ViewRef,
	CarouselContent,
	Slide,
	ImageContainer,
	ButtonContainer,
	Dots,
} from './GalleryComponent.styles';

type GalleryProps = {
	title: string;
	description: string;
	label?: string;
	images: any;
};

export const GalleryComponent: FC<GalleryProps> = ({ title, description, label, images }) => {
	const hasMounted = useHasMounted();
	const [viewportRef, embla] = useEmblaCarousel({
		active: true,
		skipSnaps: false,
		align: 'start',
		containScroll: 'trimSnaps',
		inViewThreshold: 100,
	});
	const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const [scrollSnaps, setScrollSnaps] = useState<Array<number>>([]);
	const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla]);
	const scrollPrev = useCallback(() => {
		if (!embla) return;
		embla.scrollPrev();
	}, [embla]);
	const scrollNext = useCallback(() => {
		if (!embla) return;
		embla.scrollNext();
	}, [embla]);
	const onSelect = useCallback(() => {
		if (!embla) return;
		setSelectedIndex(embla.selectedScrollSnap());
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla]);
	useEffect(() => {
		if (embla) {
			setScrollSnaps(embla.scrollSnapList());
			embla.on('select', onSelect);
			onSelect();
		}
	}, [embla, onSelect]);

	if (!hasMounted) {
		return null;
	}

	return (
		<GalleryContainer>
			<BackgroundMask />
			<GallerySection>
				<InfoContainer>
					<HeadText>
						{!!label && <TagTextSmall>{label}</TagTextSmall>}
						<H1>{title}</H1>
					</HeadText>
					<BodyText>{description}</BodyText>
				</InfoContainer>
				<ImageCarouselSection>
					<CarouselSection>
						<ViewRef ref={viewportRef}>
							<CarouselContent>
								{images?.map((image, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<Slide key={index}>
										<ImageContainer>
											<Image layout="fill" objectFit="cover" src={processCmsImageUrl(image.attributes.url)} />
										</ImageContainer>
									</Slide>
								))}
							</CarouselContent>
						</ViewRef>
					</CarouselSection>
					<ButtonContainer>
						<CircleNextBtn onClick={scrollNext} disabled={!nextBtnEnabled}>
							<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
						</CircleNextBtn>
						<CirclePrevBtn onClick={scrollPrev} disabled={!prevBtnEnabled}>
							<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
						</CirclePrevBtn>
					</ButtonContainer>
				</ImageCarouselSection>
			</GallerySection>
			<Dots>
				{scrollSnaps.map((_, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<EmblaDotButtonBlue key={`${index}`} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
				))}
			</Dots>
		</GalleryContainer>
	);
};

import Image from 'next/image';
import React, { useEffect, useCallback, useState, FC } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { BodyText, H3 } from '../../ui/core/Typography/typography.styles';
import { useHasMounted } from '../../hooks/useHasMounted';
import { processCmsImageUrl } from '../../utils/urls';
import { ButtonGroup } from '../../ui/core/Buttons/Buttons';
import { Icon, ICON_TYPE } from '../IconComponent/IconComponent';
import { CarouselContainer, CircleNextBtn, CirclePrevBtn } from '../EmblaCarousel/EmblaActionButtons.styles';
import Media from '../../models/strapi/Media/Media';
import Button from '../../models/strapi/Button';
import {
	Slide,
	ImageContainer,
	ViewRef,
	CardContainer,
	InfoContent,
	ButtonContainer,
	TextContainer,
	InfoCardContainer,
	CardSection,
	CardContent,
	CarouselButtons,
	InfoSection,
} from './InfoCardComponent.styles';

type CardProps = {
	id: string;
	image: Media;
	title: string;
	description: string;
	button: Button;
};

type InfoCardsProps = {
	cards: CardProps[];
};

const Card: FC<CardProps> = ({ image, title, description, button }) => {
	return (
		<Slide>
			<CardContainer>
				<InfoSection>
					<ImageContainer>
						<Image layout="fill" objectFit="cover" src={processCmsImageUrl(image.data.attributes.url)} />
					</ImageContainer>
					<InfoContent>
						<TextContainer>
							<H3>{title}</H3>
							{!!description && <BodyText>{description}</BodyText>}
						</TextContainer>
					</InfoContent>
				</InfoSection>
				<ButtonContainer>{button && <ButtonGroup text={button.text} type={button.type} url={button.url} color={'black'} />}</ButtonContainer>
			</CardContainer>
		</Slide>
	);
};

export const InfoCardsComponent: FC<InfoCardsProps> = ({ cards }) => {
	const hasMounted = useHasMounted();
	const [viewportRef, embla] = useEmblaCarousel({
		active: true,
		skipSnaps: false,
		align: 'start',
		containScroll: 'trimSnaps',
		inViewThreshold: 100,
	});
	const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
	const scrollPrev = useCallback(() => {
		if (!embla) return;
		embla.scrollPrev();
	}, [embla]);
	const scrollNext = useCallback(() => {
		if (!embla) return;
		embla.scrollNext();
	}, [embla]);
	const onSelect = useCallback(() => {
		if (!embla) return;
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla]);

	useEffect(() => {
		if (embla) {
			embla.on('select', onSelect);
			embla.on('resize', onSelect);
			onSelect();
		}
	}, [embla, onSelect]);

	if (!hasMounted) {
		return null;
	}

	return (
		<InfoCardContainer>
			<CardSection>
				<CarouselContainer>
					<ViewRef ref={viewportRef}>
						<CardContent>
							{cards?.map((card) => (
								<Card key={card.id} id={card.id} image={card.image} title={card.title} description={card.description} button={card.button} />
							))}
						</CardContent>
					</ViewRef>
				</CarouselContainer>
				<CarouselButtons>
					<CircleNextBtn onClick={scrollNext} disabled={!nextBtnEnabled}>
						<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
					</CircleNextBtn>
					<CirclePrevBtn onClick={scrollPrev} disabled={!prevBtnEnabled}>
						<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
					</CirclePrevBtn>
				</CarouselButtons>
			</CardSection>
		</InfoCardContainer>
	);
};

import React, { useCallback, useEffect, useState, useContext } from 'react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import useEmblaCarousel from 'embla-carousel-react';
import { useTheme } from 'styled-components';
import { Icon, ICON_TYPE } from '../IconComponent/IconComponent';
import { H2, H5 } from '../../ui/core/Typography/typography.styles';
import { processCmsImageUrl } from '../../utils/urls';
import { InstructionsButton } from '../../ui/core/Buttons/Buttons';
import { UserContext } from '../../contexts/UserContext';
import {
	WhereContainer,
	WhereHeadContainer,
	WhereBodyContainer,
	WhereItem,
	WhereIconContainer,
	WhereItemBody,
	WhereTitleContainer,
	WhereTextContainer,
	MoreButtonContainer,
	IntroTexColor,
	Slide,
	WhereCarousel,
	WhereCarouselContent,
	BodyTexColor,
	CardIcon,
} from './WhereToWatch.styles';
import { useHasMounted } from '../../hooks/useHasMounted';
import { NextBtn, PrevBtn } from '../EmblaCarousel/EmblaActionButtons.styles';

type CarouselProps = {
	disabled?: boolean;
	cards?: any[];
};

export const WhereToWatch: React.FC<CarouselProps> = ({ disabled, cards }) => {
	const { t } = useTranslation();
	const { isLoggedIn } = useContext(UserContext);
	const { screenLayout } = useTheme();
	const [viewportRef, embla] = useEmblaCarousel({
		skipSnaps: false,
		align: 'start',
		containScroll: 'trimSnaps',
		inViewThreshold: 0.9,

		breakpoints: {
			[`(min-width: ${screenLayout.mobile})`]: { slidesToScroll: 1 },
			[`(min-width: ${screenLayout.tabletLandscape})`]: { slidesToScroll: 2 },
			[`(min-width: ${screenLayout.desktop})`]: { slidesToScroll: 3 },
			[`(min-width: ${screenLayout.desktopLarge})`]: { slidesToScroll: 4, align: 'center' },
			[`(min-width: ${screenLayout.desktopXLarge})`]: { slidesToScroll: 5 },
		},
	});
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

	const onSelect = useCallback(() => {
		if (!embla) return;
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla]);

	useEffect(() => {
		if (!embla) return;
		onSelect();
		embla.on('select', onSelect);
		embla.on('resize', onSelect);
	}, [embla, onSelect]);

	const hasMounted = useHasMounted();

	const renderCarditems = () => {
		return cards?.map((card) => renderCardItem(card));
	};

	const renderCardItem = (card) => {
		return (
			<Slide key={card?.id}>
				<WhereItem>
					<WhereIconContainer>
						<CardIcon src={processCmsImageUrl(card?.icon?.data?.attributes?.url)} />
					</WhereIconContainer>
					<WhereItemBody>
						<WhereTitleContainer>
							<H5>{card?.title}</H5>
						</WhereTitleContainer>

						<WhereTextContainer>
							<BodyTexColor>{card?.text}</BodyTexColor>
						</WhereTextContainer>

						<MoreButtonContainer>
							<InstructionsButton text={t('WhereToWatch.Instructions')} url={card?.link} />
						</MoreButtonContainer>
					</WhereItemBody>
				</WhereItem>
			</Slide>
		);
	};

	if (!hasMounted) {
		return null;
	}

	return (
		<>
			{!isLoggedIn && !disabled ? (
				<WhereContainer>
					<WhereHeadContainer>
						<H2>{t('WhereToWatch.Title')}</H2>
						<IntroTexColor>{t('WhereToWatch.Text')}</IntroTexColor>
					</WhereHeadContainer>
					<WhereBodyContainer>
						<PrevBtn onClick={scrollPrev} disabled={!prevBtnEnabled}>
							<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
						</PrevBtn>
						<NextBtn onClick={scrollNext} disabled={!nextBtnEnabled}>
							<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
						</NextBtn>
						<WhereCarousel ref={viewportRef}>
							<WhereCarouselContent>{renderCarditems()}</WhereCarouselContent>
						</WhereCarousel>
					</WhereBodyContainer>
				</WhereContainer>
			) : (
				<> </>
			)}
		</>
	);
};

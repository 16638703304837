import React, { useEffect, useState } from 'react';
import { LogoItem } from './SportLogoItem';
import { Slide, SportLogoCarousel, SportLogoCarouselContent } from './SportLogosLive.styles';

type Props = {
	items?: any[];
	direction?: string;
	speed?: number;
};

export const LogoCarousel: React.FC<Props> = ({ items, direction, speed }) => {
	const [logolistPrimary, setLogoListP] = useState([]);
	const [logolistSecondary, setLogoListS] = useState([]);
	const [isHovering, setHovering] = useState(false);
	const onMouseEnter = () => setHovering(true);
	const onMouseLeave = () => setHovering(false);

	useEffect(() => {
		setLogoListP(items);
		setLogoListS(items);
	}, [items]);

	const renderLogoItems = (itemList) => {
		return itemList.map((item, index) => renderLogoItem(item, index));
	};

	const renderLogoItem = (item, index) => {
		return (
			<Slide key={index}>
				<LogoItem item={item} />
			</Slide>
		);
	};

	return (
		<SportLogoCarousel onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<SportLogoCarouselContent value={`primary${direction}`} speed={speed} hovering={isHovering}>
				{renderLogoItems(logolistPrimary)}
			</SportLogoCarouselContent>
			<SportLogoCarouselContent value={`secondary${direction}`} speed={speed} hovering={isHovering}>
				{renderLogoItems(logolistSecondary)}
			</SportLogoCarouselContent>
		</SportLogoCarousel>
	);
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext';
import { useHasMounted } from '../../hooks/useHasMounted';
import PageContent from '../../models/strapi/PageContent';
import ContentSectionComponent from './ContentSectionComponent';

type PageSectionProps = {
	data: PageContent;
	enforceLoggedOut: boolean;
};

const PageSectionComponent: React.FC<PageSectionProps> = ({ data, enforceLoggedOut }) => {
	const { i18n } = useTranslation();
	// if a another language than 'is' is returned by the cms use it, otherwise default to icelandic
	const sections = data?.getContentSectionByLocale(i18n.language);
	const { isLoggedIn } = useContext(UserContext);
	const hasMounted = useHasMounted();

	if (!hasMounted || (enforceLoggedOut && isLoggedIn)) {
		return null;
	}

	return (
		<>
			{sections?.length > 0 &&
				sections.map((section, key) => {
					// no other index is available here
					// eslint-disable-next-line react/no-array-index-key
					return <ContentSectionComponent section={section} key={key} />;
				})}
		</>
	);
};

export default PageSectionComponent;

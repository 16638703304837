import styled from 'styled-components';
import { breakpoints } from '../styles/breakpoints';
import { ellipsis } from '../styles/ellipsis';

export const ListTitle = styled.h2`
	padding: 5px 20px 5px 0;
	margin-bottom: 0;
	text-transform: uppercase;
	font-size: 22px;
	color: #eee;
	${ellipsis}

	@media ${breakpoints.sm} {
		font-size: 24px;
	}

	@media ${breakpoints.md} {
		font-size: 30px;
	}
`;

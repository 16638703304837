import React, { FC } from 'react';
import styled from 'styled-components';
import PriceTable from '../models/strapi/PriceTable';
import { H2, H4, H6, BodyText } from '../ui/core/Typography/typography.styles';
import { media } from '../ui/utils/media';

const ComponentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background: transparent;
	padding: 0px;
	gap: 16px;

	${media.tabletLandscapeUp} {
		padding: 64px 0px;
	}
`;

const ComponentSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	width: 100%;
	max-width: 1220px;
	gap: 64px;

	h2,
	h4 {
		color: ${({ theme }) => theme.colors.white};
	}

	h6 {
		color: ${({ theme }) => theme.colors.tealPlus80};
		text-transform: uppercase;
	}

	p {
		color: ${({ theme }) => theme.colors.tealPlus60};
		margin: 0;
	}

	${media.tabletPortraitUp} {
		padding: 0px 96px;
	}
`;

const ComponentHead = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;

	${media.tabletLandscapeUp} {
		flex-direction: row;
		align-items: flex-end;
	}
`;

const ComponentTables = styled.div`
	display: flex;
	flex-direction: column;
	gap: 64px;
	width: 100%;
`;

const TableContainer = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
`;

const TableItemSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const ItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding: 18px 16px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.tealPlus20};
`;

type PriceTableProps = {
	title: string;
	dateLabel: string;
	priceTables: PriceTable[];
};

export const PriceTableComponent: FC<PriceTableProps> = ({ title, dateLabel, priceTables }) => {
	return (
		<ComponentContainer>
			<ComponentSection>
				<ComponentHead>
					<H2>{title}</H2>
					<BodyText>{dateLabel}</BodyText>
				</ComponentHead>
				<ComponentTables>
					{priceTables.map((table) => (
						<TableContainer key={table.id}>
							<H4>{table.title}</H4>
							<TableItemSection>
								{table.items.map((item) => (
									<ItemContainer key={item.id}>
										{item.isHeader ? (
											<>
												<H6>{item.name}</H6>
												<H6>{item.price}</H6>
											</>
										) : (
											<>
												<BodyText>{item.name}</BodyText>
												<BodyText>{item.price}</BodyText>
											</>
										)}
									</ItemContainer>
								))}
							</TableItemSection>
						</TableContainer>
					))}
				</ComponentTables>
			</ComponentSection>
		</ComponentContainer>
	);
};

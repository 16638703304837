import styled from 'styled-components';
import { media } from '../../ui/utils/media';

export const GalleryContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	background: transparent;
	padding: 64px 0px;
	z-index: 1;
	gap: 16px;
`;

export const BackgroundMask = styled.div`
	display: block;
	overflow: hidden;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
	margin: 0px -5%;
	background: ${({ theme }) => theme.colors.white};
`;

export const GallerySection = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	padding: 0px;
	width: 100%;
	z-index: 3;
	gap: 32px;

	${media.tabletLandscapeUp} {
		flex-direction: row;
		padding: 0px 48px;
	}

	${media.desktopUp} {
		padding: 0px 64px;
	}
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	gap: 32px;

	${media.tabletLandscapeUp} {
		width: 25%;
	}

	h1,
	p {
		color: ${({ theme }) => theme.colors.black};
		margin: 0;
	}
`;

export const HeadText = styled.div``;

export const ImageCarouselSection = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	width: 100%;
	height: 246px;
	border-radius: 8px;
	overflow: hidden;

	${media.tabletPortraitUp} {
		height: 366px;
	}

	${media.tabletLandscapeUp} {
		height: 486px;
		width: 75%;
	}

	${media.desktopLargeUp} {
		height: 606px;
	}
`;

export const CarouselSection = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;

export const ViewRef = styled.div`
	height: 100%;
`;

export const CarouselContent = styled.div`
	display: flex;
	position: relative;
	flex-direction: row;
	height: 100%;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-webkit-tap-highlight-color: transparent;
	will-change: transform;
`;

export const Slide = styled.div`
	display: flex;
	position: relative;
	flex: 0 0 100%;
`;

export const ImageContainer = styled.div`
	position: 'relative';
	width: 100%;
	height: 100%;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 0px 16px;
	right: 0;
`;

export const Dots = styled.div`
	display: flex;
	list-style: none;
	justify-content: center;
	width: 100%;
	z-index: 3;
	padding: 0;
	gap: 5px;

	${media.tabletLandscapeUp} {
		justify-content: flex-end;
		padding: 0px 48px;
	}

	${media.desktopUp} {
		padding: 0px 64px;
	}
`;

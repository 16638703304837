import styled from 'styled-components';
import { IntroText, BodyText, sharedFontStyles } from '../../ui/core/Typography/typography.styles';
import { media } from '../../ui/utils/media';

export const IntroTexColor = styled(IntroText)`
	color: ${({ theme }) => theme.colors.tealPlus40};
`;

export const BodyTextColor = styled(BodyText)`
	color: ${({ theme }) => theme.colors.tealPlus60};
`;

export const PriceText = styled.p<{ isBold?: boolean }>`
	${sharedFontStyles};
	font-weight: ${(props) => (props.isBold ? 600 : 400)};
	font-size: ${(props) => (props.isBold ? 24 : 20)}px;
	line-height: ${(props) => (props.isBold ? 29 : 30)}px;
	margin: 0;
	padding: 0;
`;

export const PackageContainer = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	padding: 64px 0px;
	gap: 48px;
`;

export const PackageHeadContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	gap: 10px;
`;

export const PackageBodyContainer = styled.div`
	position: relative;
`;

export const PackageCarousel = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const PackageCarouselContent = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-webkit-tap-highlight-color: transparent;
	margin-left: -24px;
`;

export const Slide = styled.div`
	position: relative;
`;

export const PackageItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0px 12px;
	width: 320px;
	background-color: ${({ theme }) => theme.colors.tealMinus70};
	box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.05), 0px 8px 10px 1px rgba(0, 0, 0, 0.04), 0px 3px 14px 2px rgba(0, 0, 0, 0.04);
	border-radius: 12px;
	transition: background-color 0.5s ease;

	&:hover {
		background-color: #10292c;
	}
	&:hover img {
		transform: scale(1.05);
	}

	${media.tabletPortraitUp} {
		width: 420px;
		padding: 0px;

		&:hover img {
			transform: scale(1.2);
		}
	}
`;

export const PackageImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 100%;
	flex: none;
	order: 0;
	flex-grow: 0;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
`;

export const PackageImage = styled.img`
	display: block;
	position: relative;
	height: 100%;
	transition: transform 0.25s;
`;

export const PackageItemBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	gap: 16px;
	flex: none;
	order: 1;
	align-self: stretch;
	flex-grow: 1;
	width: 100%;
	height: 210px;
	border-bottom: 1px solid ${({ theme }) => theme.misc.blackTransparent}; ;
`;

export const PackageTitleContainer = styled.div``;

export const PackageTextContainer = styled.div``;

export const PackageFooterContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px 24px 24px;
	gap: 16px;
	width: 100%;
	flex: none;
	order: 3;
	flex-grow: 0;
`;

export const PriceContainer = styled.div`
	display: flex;
	gap: 5px;
`;

export const ButtonContainer = styled.div`
	width: 100%;
`;

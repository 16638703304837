import styled from 'styled-components';
import { media } from '../../../ui/utils/media';

export const InfoContainer = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	background: transparent;
	padding: 76px 0px;
	z-index: 2;
`;

export const ColorMask = styled.div`
	position: absolute;
	filter: blur(175px);
	transform: translateZ(0);
`;

export const BackgroundFade = styled.div`
	display: block;
	overflow: hidden;
	position: absolute;
	left: 0%;
	right: 0%;
	top: 0%;
	bottom: 0%;
	z-index: 1;
	opacity: 0;
	margin: 0px -5%;
	background: ${({ theme }) => theme.colors.black};
`;

export const BackgroundMask = styled.div`
	display: block;
	overflow: hidden;
	position: absolute;
	left: 0%;
	right: 0%;
	top: -5%;
	bottom: -5%;
	z-index: 1;
	opacity: 0.5;
	margin: 0px -5%;
	background: ${({ theme }) => theme.colors.white};
	mask-image: linear-gradient(0deg, transparent 1%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 20%, transparent 99%);

	#mask1 {
		left: -34.53%;
		right: 38.75%;
		top: -31.83%;
		bottom: 41%;
		background: ${({ theme }) => theme.colors.teal};
	}

	#mask2 {
		left: 61.69%;
		right: -34.27%;
		top: 53.67%;
		bottom: -22.5%;
		background: ${({ theme }) => theme.colors.orange};
	}

	#mask3 {
		left: 13.36%;
		right: 10.9%;
		top: 38.08%;
		bottom: -9.92%;
		background: ${({ theme }) => theme.colors.passionPurple};
	}

	#mask4 {
		position: absolute;
		left: -30.14%;
		right: 65.29%;
		top: 53.67%;
		bottom: -15.17%;
		background: ${({ theme }) => theme.colors.tealPlus20};
	}

	#mask5 {
		position: absolute;
		left: 38.31%;
		right: -30.58%;
		top: -30.17%;
		bottom: 42.67%;
		background: ${({ theme }) => theme.colors.tealLight};
	}
`;

export const InfoSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: left;
	width: 100%;
	max-width: 1440px;
	gap: 32px;
	z-index: 3;

	padding: 0px;

	${media.tabletLandscapeUp} {
		padding: 0px 96px;
	}

	${media.desktopUp} {
		gap: 64px;
	}
`;

export const TopSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 0px;
	gap: 32px;

	${media.tabletLandscapeUp} {
		flex-direction: row;
	}
`;

export const InfoImage = styled.div`
	position: relative;
	width: 100%;
	min-height: 320px;
	max-width: 100%;
	border-radius: 8px;
	overflow: hidden;

	img {
		object-position: 50% 20%;
	}

	${media.tabletPortraitUp} {
		min-height: 400px;
	}

	${media.tabletLandscapeUp} {
		min-height: 515px;
		width: 50%;
		min-width: 515px;
	}
`;

export const InfoContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0px;
	width: 100%;
	gap: 40px;

	${media.tabletLandscapeUp} {
		width: 50%;
		padding: 0px 0px 0px 16px;
	}

	${media.desktopUp} {
		width: 50%;
		padding: 0px 15% 0px 16px;
	}

	h2,
	p {
		margin: 0;
	}
`;

export const BottomSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 32px;

	h4 {
		margin: 0;
		text-transform: uppercase;
	}
`;

export const BottomHead = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const CarouselButtons = styled.div`
	display: flex;
	flex-direction: row-reverse;
	position: relative;
	gap: 16px;
`;

export const CarouselSection = styled.div`
	position: relative;
`;

export const ViewRef = styled.div`
	height: 100%;
	width: 100%;

	${media.tabletPortraitUp} {
		width: 70vw;
	}
`;

export const CarouselContent = styled.div<{ isRow: boolean }>`
	display: flex;
	flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
	height: 100%;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-webkit-tap-highlight-color: transparent;
	will-change: transform;
	gap: 32px;

	${media.tabletPortraitUp} {
		flex-direction: row;
	}
`;

// Profile Cards

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const ImageBox = styled.div<{ isRow: boolean }>`
	position: relative;
	width: ${({ isRow }) => (isRow ? '200px' : '100%')};
	height: 226px;
	overflow: hidden;
	border-radius: 8px;

	img {
		object-position: 50% 30%;
	}

	${media.tabletPortraitUp} {
		width: 226px;
	}

	${media.tabletLandscapeUp} {
		width: 304px;
		height: 304px;
	}
`;

export const TextBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px 16px;

	h6,
	p {
		margin: 0;
	}
`;

import React from 'react';
import styled from 'styled-components';

const VideoEmbedFrame = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: block;
	width: 100%;
	height: 100%;
	border: none;
`;

type VideoEmbedProps = {
	url: string;
};

export const VideoEmbed: React.FC<VideoEmbedProps> = ({ url = '' }) => {
	const vidUrl = url?.replace('/watch?v=', '/embed/').split('&');

	return (
		<VideoEmbedFrame
			src={vidUrl[0] ?? ''}
			title="YouTube video player"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share, fullscreen"
			allowFullScreen
		/>
	);
};

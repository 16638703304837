import React, { FC, useState } from 'react';
import { BodyText, H2 } from '../../ui/core/Typography/typography.styles';
import { Icon, ICON_TYPE } from '../IconComponent/IconComponent';
import { useHasMounted } from '../../hooks/useHasMounted';
import { QuestionItem, QuestionHead, IconContainer, QuestionAnswer, FAQContainer, BackgroundMask, FlexContainer, FaqSection, HeadSection, QuestionSection } from './FaqComponent.styles';

type QuestionProps = {
	id: string;
	question: string;
	answer: string;
};

type FaqProps = {
	title: string;
	description: string;
	questions: QuestionProps[];
};

const Question: FC<QuestionProps> = ({ id, question, answer }) => {
	const [open, setOpen] = useState<boolean>(false);

	return (
		<QuestionItem id={id} isOpen={open} onClick={() => setOpen(!open)}>
			<QuestionHead>
				<strong>
					<BodyText>{question}</BodyText>
				</strong>
				<IconContainer isOpen={open}>
					<Icon type={ICON_TYPE.ARROW_DOWN} />
				</IconContainer>
			</QuestionHead>
			<QuestionAnswer isOpen={open}>
				<BodyText>{answer}</BodyText>
			</QuestionAnswer>
		</QuestionItem>
	);
};

export const FaqComponent: FC<FaqProps> = ({ title, description, questions = [] }) => {
	const hasMounted = useHasMounted();

	if (!hasMounted) {
		return null;
	}

	return (
		<FAQContainer>
			<BackgroundMask />
			<FlexContainer>
				<FaqSection>
					<HeadSection>
						<H2>{title}</H2>
						<BodyText>{description}</BodyText>
					</HeadSection>
					<QuestionSection>
						{questions?.map((question, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<Question key={index} id={question.id} question={question.question} answer={question.answer} />
						))}
					</QuestionSection>
				</FaqSection>
			</FlexContainer>
		</FAQContainer>
	);
};

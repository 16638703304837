import React, { FC, useMemo, useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Link from 'next/link';
import { Content } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { Icon, ICON_TYPE } from '../IconComponent/IconComponent';
import { useHasMounted } from '../../hooks/useHasMounted';
import { ListTitle } from '../ListTitle';
import PresentationComponent from '../PresentationComponent';
import { NextBtn, PrevBtn } from './EmblaActionButtons.styles';
import Button from '../../models/strapi/Button';
import { ContentContainer, PresentationSection, SeeAllLink, TitleContainer, TopListText, TopListTextContainer, ViewPort, ViewPortContent, CarouselContainer } from './EmblaMediaCarousel.styles';
import MediaPoster from '../../models/strapi/MediaPoster';

type EmblaMediaCarouselProps = {
	id: string;
	label?: string;
	title?: string;
	description?: string;
	button?: Button;
	detailsUrl?: string;
	isTopList?: boolean;
	isPresentation?: boolean;
	items?: Content[] | MediaPoster[];
	renderItem: (item: any, clickEnabled: boolean) => any;
};

export const EmblaMediaCarousel: FC<EmblaMediaCarouselProps> = ({ id, label, title, description, detailsUrl, isPresentation, isTopList, button, items, renderItem }) => {
	const hasMounted = useHasMounted();
	const breakpointRef = {
		'(min-width: 768px})': { slidesToScroll: 2 },
		'(min-width: 1024px)': { slidesToScroll: 4 },
	};
	const [viewportRef, embla] = useEmblaCarousel({
		skipSnaps: false,
		align: 'start',
		containScroll: 'trimSnaps',
		slidesToScroll: 'auto',
		inViewThreshold: 1,
		breakpoints: isTopList && breakpointRef,
	});
	const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
	const onSelect = useCallback(() => {
		if (!embla) return;
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla]);

	useEffect(() => {
		if (!embla) return;
		onSelect();
		embla.on('select', onSelect);
		embla.on('resize', onSelect);
	}, [embla, onSelect]);

	useEffect(() => {
		if (!embla) return;
		embla.reInit();
	}, [embla, items]);

	const titleComponent = useMemo(() => {
		if (!title) return null;
		if (!detailsUrl) {
			return (
				<TitleContainer withPresentation={isPresentation}>
					<ListTitle>{title}</ListTitle>
				</TitleContainer>
			);
		}
		return (
			<TitleContainer withPresentation={isPresentation}>
				<Link href={detailsUrl} passHref>
					<SeeAllLink>
						<ListTitle>{title}</ListTitle>
						<Icon type="ARROW_RIGHT" />
					</SeeAllLink>
				</Link>
			</TitleContainer>
		);
	}, [title, detailsUrl, isPresentation]);

	const itemComponents = useMemo(() => {
		if (!items || items.length === 0) return null;
		if (isTopList) {
			return (
				/* eslint-disable react/no-array-index-key */
				<>
					{items.map((item: Content | MediaPoster, index: number) => (
						<React.Fragment key={index}>
							<TopListTextContainer key={`U${item.id}${index}}D`}>
								<TopListText>{index + 1}</TopListText>
							</TopListTextContainer>
							{renderItem(item, true)}
						</React.Fragment>
					))}
				</>
			);
		}
		return items.map((item) => renderItem(item, true));
	}, [isTopList, items, renderItem]);

	if (!items || items.length === 0) return null;

	if (!hasMounted) {
		return null;
	}

	return (
		<CarouselContainer id={id}>
			{titleComponent}
			<ContentContainer>
				<ViewPort ref={viewportRef}>
					<ViewPortContent>
						{isPresentation && (
							<PresentationSection key={id} id={id}>
								<PresentationComponent label={label} title={title} description={description} url={detailsUrl} button={button} />
							</PresentationSection>
						)}
						{itemComponents}
					</ViewPortContent>
				</ViewPort>
				<PrevBtn onClick={scrollPrev} disabled={!prevBtnEnabled}>
					<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
				</PrevBtn>
				<NextBtn onClick={scrollNext} disabled={!nextBtnEnabled}>
					<Icon type={ICON_TYPE.ARROW_RIGHT_L} />
				</NextBtn>
			</ContentContainer>
		</CarouselContainer>
	);
};

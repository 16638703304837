import styled from 'styled-components';
import { BodyText, IntroText } from '../../ui/core/Typography/typography.styles';
import { media } from '../../ui/utils/media';

export const BodyTexColor = styled(BodyText)`
	color: ${({ theme }) => theme.colors.tealPlus40};
`;

export const IntroTexColor = styled(IntroText)`
	color: ${({ theme }) => theme.colors.tealPlus40};
`;

export const WhereContainer = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	padding: 64px 0px;
	gap: 48px;
`;

export const WhereHeadContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	gap: 10px;
`;

export const WhereBodyContainer = styled.div`
	position: relative;
`;

export const WhereCarousel = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const WhereCarouselContent = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-webkit-tap-highlight-color: transparent;
	margin-left: -24px;

	${media.desktopLargeUp} {
		justify-content: center;
	}
`;

export const Slide = styled.div`
	position: relative;
`;

export const WhereItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 0px;
	margin: 0px 12px;
	background: ${({ theme }) => theme.colors.tealMinus80};
	border-radius: 12px;
	height: 420px;
	width: 320px;

	${media.tabletPortraitUp} {
		width: 420px;
	}
`;

export const WhereIconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 2px;
	height: 200px;
`;

export const CardIcon = styled.img``;

export const WhereItemBody = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0px 24px;
	gap: 16px;
`;

export const WhereTitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px;
`;

export const WhereTextContainer = styled.div`
	text-align: center;
	padding: 0px 16px;
	gap: 10px;
`;

export const MoreButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 16px;
`;

export const MoreButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 13px 16px;
	width: 100%;
	border: 1px solid rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(6px);
	/* Note: backdrop-filter has minimal browser support */

	border-radius: 6px;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../models/strapi/PageContent';
import { MetaTags } from '../MetaTags';

type PageMataDataProps = {
	data: PageContent;
};

const PageMetaDataComponent: React.FC<PageMataDataProps> = ({ data }) => {
	const { i18n } = useTranslation();
	// if a another language than 'is' is returned by the cms use it, otherwise default to icelandic
	const metaData = data?.getPageMetaDataByLocale(i18n.language);

	return <>{metaData && <MetaTags title={metaData?.metaTitle} description={metaData?.metaDescription} socialImageUrl={metaData?.shareImage?.data?.attributes?.url} />}</>;
};

export default PageMetaDataComponent;

import React, { FC } from 'react';
import { Category, ModuleType, ModuleTemplate, useCategory } from '@vodafoneis/sjonvarpskjarni-js-lib';
import useContentGridRenderer from '../hooks/useContentGridRenderer';
import { EmblaMediaCarousel } from './EmblaCarousel/EmblaMediaCarosel';
import Presentation from '../models/strapi/PresentationCarousel';
import { CONTENT_LIMIT } from '../config/constants';

type PresentationCarouselProps = {
	id: string;
	title?: string;
	categoryID?: number;
	category?: Category;
	presentation?: Presentation;
	useStrapi?: boolean;
};

const PresentationCarousel: FC<PresentationCarouselProps> = ({ id, title, category, presentation, categoryID, useStrapi }) => {
	const moduleTemplate = ModuleTemplate.PROMO;
	const moduleType = ModuleType.POSTER;
	const categoryFromId = useCategory(categoryID, CONTENT_LIMIT);
	const renderItem = useContentGridRenderer({ moduleType, moduleTemplate });

	let content;
	let detailsUrl;
	if (!useStrapi) {
		if (!category.content) return null;
		content = category.content;
		detailsUrl = `/category/${category.id}/grid`;
	} else {
		content = categoryFromId.category ? categoryFromId?.category?.content : presentation.mediaList;
		detailsUrl = categoryID ? `/category/${categoryID}/grid` : null;
	}

	return (
		<EmblaMediaCarousel
			id={id}
			label={presentation.label}
			description={presentation.description}
			button={presentation.button}
			title={title || category.title}
			items={content}
			renderItem={renderItem}
			isPresentation
			detailsUrl={detailsUrl}
		/>
	);
};

export default PresentationCarousel;

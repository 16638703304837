import Image from 'next/image';
import React, { FC } from 'react';
import { BodyTextTiny, H6 } from '../../../ui/core/Typography/typography.styles';
import { processCmsImageUrl } from '../../../utils/urls';
import { CardContainer, ImageBox, TextBox } from './InfoComponent.styles';

type ProfileCardProps = {
	isRow: boolean;
	imageUrl: string;
	alternativeText: string;
	name: string;
	description: string;
};

export const ProfileCard: FC<ProfileCardProps> = ({ isRow, imageUrl, alternativeText, name, description }) => {
	return (
		<CardContainer>
			<ImageBox isRow={isRow}>
				<Image layout="fill" objectFit="cover" src={processCmsImageUrl(imageUrl)} alt={alternativeText} />
			</ImageBox>
			<TextBox>
				<H6>{name}</H6>
				<BodyTextTiny>{description}</BodyTextTiny>
			</TextBox>
		</CardContainer>
	);
};

import { useState, useEffect } from 'react';
import { useWindowSize } from 'react-use';
import { useTheme } from 'styled-components';

export const useDevicetype = () => {
	const [deviceType, setDeviceType] = useState('desktop');

	const { width } = useWindowSize();
	const { screenLayout } = useTheme();

	useEffect(() => {
		if (width < screenLayout.tabletPortrait && deviceType !== 'mobile') {
			setDeviceType('mobile');
		} else if (width >= screenLayout.tabletPortrait && width < screenLayout.tabletLandscape && deviceType !== 'tabletPortrait') {
			setDeviceType('tabletPortrait');
		} else if (width >= screenLayout.tabletLandscape && width < screenLayout.desktop && deviceType !== 'tabletLandscape') {
			setDeviceType('tabletLandscape');
		} else if (width >= screenLayout.desktop && deviceType !== 'desktop') {
			setDeviceType('desktop');
		}
	}, [deviceType, width, screenLayout]);
	return deviceType;
};

import React, { FC } from 'react';
import { H2 } from '../../ui/core/Typography/typography.styles';
import { TitleContainer, TextContainer, TextAlignment, TitleWrapper } from './TitleComponent.styles';

type TitleProps = {
	titleText: string;
	textAlignment: TextAlignment;
};

export const TitleComponent: FC<TitleProps> = ({ titleText, textAlignment }) => {
	const textArray = titleText.split('. ');

	return (
		<TitleContainer>
			<TitleWrapper alignment={textAlignment}>
				<TextContainer alignment={textAlignment}>
					{!!titleText && (
						<H2>
							{textArray?.map((text, index) => {
								// eslint-disable-next-line react/no-array-index-key
								return <span key={index}>{text}</span>;
							})}
						</H2>
					)}
				</TextContainer>
			</TitleWrapper>
		</TitleContainer>
	);
};

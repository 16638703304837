import React from 'react';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';

export const MetaTags: React.FC<{ title: string; description?: string; socialImageUrl?: string; type?: string; url?: string }> = ({ title, description, socialImageUrl, type, url }) => {
	const { t } = useTranslation();

	return (
		<Head>
			<title>{t('Main.Subtitle', { title })}</title>
			<meta property={'og:title'} content={title} />
			{description && <meta property={'og:description'} content={description} />}
			{socialImageUrl && <meta property={'og:image'} content={socialImageUrl} />}
			<meta property={'og:type'} content={type ?? 'website'} />
			{url && <meta property={'og:url'} content={`https://sjonvarp.stod2.is${url}`} />}
		</Head>
	);
};

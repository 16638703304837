import styled from 'styled-components';
import { media } from '../../ui/utils/media';

export const InfoCardContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 64px 0px;
	gap: 16px;
`;

export const CardSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1440px;
	gap: 32px;

	padding: 0px;

	${media.tabletLandscapeUp} {
		padding: 0px 96px;
	}
`;

export const CarouselButtons = styled.div`
	display: flex;
	flex-direction: row-reverse;
	gap: 16px;
`;

export const CarouselContainer = styled.div`
	position: relative;
`;

export const ViewRef = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 70vw;
`;

export const CardContent = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-webkit-tap-highlight-color: transparent;
	will-change: transform;
	gap: 32px;
`;

export const Slide = styled.div`
	position: relative;
`;

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 0;
	height: 100%;
	width: 272px;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 8px;
	overflow: hidden;

	${media.tabletPortraitUp} {
		width: 416px;
	}
`;

export const InfoSection = styled.div``;

export const ImageContainer = styled.div`
	position: relative;
	width: 100%;
	height: 204px;

	${media.tabletPortraitUp} {
		height: 312px;
	}
`;

export const InfoContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding: 16px 16px 0px 16px;

	${media.tabletPortraitUp} {
		padding: 48px 48px 0px 48px;
	}

	h3,
	p {
		color: ${({ theme }) => theme.colors.black};
	}
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	overflow: hidden;
	max-height: 500px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;

	padding: 0px 16px 16px 16px;

	${media.tabletPortraitUp} {
		padding: 0px 48px 48px 48px;
	}
`;

import React, { useState } from 'react';
import { H5, BodyText } from '../../ui/core/Typography/typography.styles';
import { processCmsImageUrl } from '../../utils/urls';
import { CardIcon, SportDiscription, SportIcon, SportLogoItem } from './SportLogosLive.styles';

type Props = {
	item?: any;
};

export const LogoItem: React.FC<Props> = ({ item }) => {
	const [isOverItem, setOverItem] = useState(false);
	const onMouseEnter = () => setOverItem(true);
	const onMouseLeave = () => setOverItem(false);

	return (
		<SportLogoItem onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<SportIcon showing={!isOverItem}>
				<CardIcon src={processCmsImageUrl(item?.logo?.data?.attributes?.url)} />
			</SportIcon>
			<SportDiscription showing={isOverItem}>
				<H5>{item?.title}</H5>
				<BodyText>{item?.desc}</BodyText>
			</SportDiscription>
		</SportLogoItem>
	);
};

import styled from 'styled-components';
import { media } from '../../ui/utils/media';

type CarouselButtonProps = {
	disabled: boolean;
};

type DotButtonProps = {
	selected: boolean;
};

export const CarouselContainer = styled.div`
	position: relative;
`;

export const CarouselRef = styled.div<{ overflow: boolean }>`
	overflow: ${({ overflow }) => (overflow ? 'hidden' : 'visible')};
`;

export const Dots = styled.div`
	display: flex;
	list-style: none;
	justify-content: center;
	position: absolute;
	width: 100%;
	top: 80%;
`;

export const EmblaDotButton = styled.button<DotButtonProps>`
	background-color: transparent;
	cursor: pointer;
	position: relative;
	padding: 0;
	outline: 0;
	border: 0;
	width: 30px;
	height: 30px;
	margin-right: 7.5px;
	margin-left: 7.5px;
	display: flex;
	align-items: center;
	:after {
		background-color: ${({ theme }) => theme.colors.white};
		opacity: ${({ selected }) => (selected ? 1 : 0.2)};
		width: 100%;
		height: 4px;
		border-radius: 2px;
		content: '';
	}
`;

const EmblaButton = styled.button<CarouselButtonProps>`
	outline: 0;
	width: 3%;
	min-width: 40px;
	height: 100%;
	background-color: ${({ theme }) => theme.colors.darkGray};
	touch-action: manipulation;
	position: absolute;
	z-index: 1;
	border: 0;
	justify-content: center;
	align-items: center;
	text-align: center;
	fill: ${({ theme }) => theme.colors.white};
	padding: 0;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	opacity: ${({ disabled }) => (disabled ? 0 : 0.4)};
	top: 50%;
	transform: translateY(-50%);
	transition: opacity 0.5s ease;

	&:hover {
		opacity: ${({ disabled }) => (disabled ? 0 : 0.6)};
	}
`;

export const NextBtn = styled(EmblaButton)<CarouselButtonProps>`
	right: 0px;
	display: none;

	${media.tabletLandscapeUp} {
		display: ${({ disabled }) => (disabled ? 'none' : 'block')};
		right: -${({ theme }) => theme.screenLayoutGutters.tabletLandscape.right}px;
	}

	${media.desktopUp} {
		right: -${({ theme }) => theme.screenLayoutGutters.desktop.right}px;
	}

	${media.desktopLargeUp} {
		right: -${({ theme }) => theme.screenLayoutGutters.desktopLarge.right}px;
	}

	${media.desktopXLargeUp} {
		right: -${({ theme }) => theme.screenLayoutGutters.desktopXLarge.right}vw;
	}
`;

export const PrevBtn = styled(EmblaButton)<CarouselButtonProps>`
	left: 0px;
	display: none;

	svg {
		transform: rotate(180deg);
	}

	${media.tabletLandscapeUp} {
		display: ${(props) => (props.disabled ? 'none' : 'block')};
		left: -${({ theme }) => theme.screenLayoutGutters.tabletLandscape.left}px;
	}

	${media.desktopUp} {
		left: -${({ theme }) => theme.screenLayoutGutters.desktop.left}px;
	}

	${media.desktopLargeUp} {
		left: -${({ theme }) => theme.screenLayoutGutters.desktopLarge.left}px;
	}

	${media.desktopXLargeUp} {
		left: -${({ theme }) => theme.screenLayoutGutters.desktopXLarge.left}vw;
	}
`;

const EmblaCircleButton = styled.button<CarouselButtonProps>`
	outline: 0;
	width: 3%;
	min-width: 44px;
	height: 44px;
	border-radius: 72px;
	background-color: ${({ theme }) => theme.colors.teal};
	touch-action: manipulation;
	z-index: 1;
	border: 0;
	justify-content: center;
	align-items: center;
	text-align: center;
	fill: ${({ theme }) => theme.colors.white};
	padding: 0;
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	opacity: ${({ disabled }) => (disabled ? 0.3 : 0.7)};
	transition: opacity 0.5s ease;

	&:hover {
		opacity: ${(props) => (props.disabled ? 0.3 : 1)};
	}
`;

export const CircleNextBtn = styled(EmblaCircleButton)<CarouselButtonProps>`
	display: none;

	${media.tabletPortraitUp} {
		display: block;
	}

	svg {
		transform: scale(0.7);
	}
`;

export const CirclePrevBtn = styled(EmblaCircleButton)<CarouselButtonProps>`
	display: none;

	svg {
		transform: rotate(180deg) scale(0.7);
	}

	${media.tabletPortraitUp} {
		display: block;
	}
`;

export const EmblaDotButtonBlue = styled.button<DotButtonProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ selected, theme }) => (selected ? theme.colors.tealLight : theme.colors.tealPlus60)};
	cursor: pointer;
	position: relative;
	padding: 0;
	outline: 0;
	border: 0;
	max-width: 30px;
	width: ${({ selected }) => (selected ? 30 : 10)}px;
	height: 10px;
	content: '';
	border-radius: 8px;

	transition: 0.5s ease;
`;

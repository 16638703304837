import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { H2 } from '../../ui/core/Typography/typography.styles';
import { IntroTexColor, SportBody, SportLogoBodyContainer, SportLogoContainer, SportLogoHeadContainer } from './SportLogosLive.styles';
import { LogoCarousel } from './SportLogoCarousel';
import { useHasMounted } from '../../hooks/useHasMounted';

type CarouselProps = {
	disabled?: boolean;
	sportLogos?: any[];
};

export const SportLogoCarousels: React.FC<CarouselProps> = ({ disabled, sportLogos }) => {
	const { t } = useTranslation();
	const [logoList1, setLogoList1] = useState([]);
	const [logoList2, setLogoList2] = useState([]);
	const logos = sportLogos;
	const hasMounted = useHasMounted();

	useEffect(() => {
		if (logos) {
			const half = Math.ceil(logos?.length / 2);
			setLogoList1(logos?.slice(0, half));
			setLogoList2(logos?.slice(half));
		}
	}, [logos]);

	if (!hasMounted) {
		return null;
	}

	return (
		<>
			{!disabled ? (
				<SportLogoContainer>
					<SportLogoHeadContainer>
						<H2>{t('SportLogoLive.Title')}</H2>
						<IntroTexColor>{t('SportLogoLive.Text')}</IntroTexColor>
					</SportLogoHeadContainer>
					<SportBody>
						<SportLogoBodyContainer>
							<LogoCarousel items={logoList1} direction={'R'} speed={50} />
						</SportLogoBodyContainer>
						<SportLogoBodyContainer>
							<LogoCarousel items={logoList2} direction={'L'} speed={50} />
						</SportLogoBodyContainer>
					</SportBody>
				</SportLogoContainer>
			) : (
				<> </>
			)}
		</>
	);
};

import styled, { keyframes } from 'styled-components';
import { IntroText, BodyText, sharedFontStyles } from '../../ui/core/Typography/typography.styles';

export const IntroTexColor = styled(IntroText)`
	color: ${({ theme }) => theme.colors.tealPlus40};
`;

export const BodyTextColor = styled(BodyText)`
	color: ${({ theme }) => theme.colors.tealPlus60};
`;

export const PriceText = styled.p<{ isBold?: boolean }>`
	${sharedFontStyles};
	font-weight: ${(props) => (props.isBold ? 600 : 400)};
	font-size: ${(props) => (props.isBold ? 24 : 20)}px;
	line-height: ${(props) => (props.isBold ? 29 : 30)}px;
	margin: 0;
	padding: 0;
`;

export const SportLogoContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 64px 0px;
	gap: 48px;
`;

export const SportLogoHeadContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	gap: 10px;
`;

export const SportBody = styled.div``;

export const SportLogoBodyContainer = styled.div`
	display: flex;
	width: 100%;
	height: 186px;
`;

export const SportLogoCarousel = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	width: 100vw;
`;

const primaryR = keyframes`
	from {
		transform: translateX(0%);
	}
	to {
		transform: translateX(100%);
	}
`;
const secondaryR = keyframes`
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0%);
	}
`;
const primaryL = keyframes`
	from {
		transform: translateX(0%);
	}
	to {
		transform: translateX(-100%);
	}
`;
const secondaryL = keyframes`
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0%);
	}
`;

function slideBuilder(path) {
	switch (path) {
		case 'primaryR':
			return primaryR;
		case 'secondaryR':
			return secondaryR;
		case 'primaryL':
			return primaryL;
		case 'secondaryL':
			return secondaryL;
		default:
			return 0;
	}
}

export const SportLogoCarouselContent = styled.div<{ value: string; speed?: number; hovering?: boolean }>`
	display: flex;
	position: absolute;
	left: 0%;
	top: 0%;
	animation: ${(props) => slideBuilder(props.value)} ${(props) => props.speed}s linear infinite;
	animation-play-state: ${(props) => (props.hovering ? 'paused' : 'running')};
`;

export const Slide = styled.div`
	position: relative;
`;

export const SportLogoItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 186px;
	width: 331px;
`;

export const SportIcon = styled.div<{ showing?: boolean }>`
	display: flex;
	justify-content: center;
	height: 100%;
	opacity: ${(props) => (props.showing ? 1 : 0.3)};
	transform: scale(${(props) => (props.showing ? 1 : 1.3)});
	transition: all 0.4s ease;
`;

export const SportDiscription = styled.div<{ showing?: boolean }>`
	padding: 5px 10px;
	position: absolute;
	justify-content: center;
	align-items: center;
	text-align: center;
	opacity: ${(props) => (props.showing ? 1 : 0)};
	transition: all 0.4s ease;
`;

export const CardIcon = styled.img`
	max-width: 80%;
	max-height: 100%;
	display: block;
	margin: auto;
`;

import React from 'react';
import { Col } from '@vodafoneis/sjonvarpskjarni-js-lib';
import styled from 'styled-components';

const StyledColumn = styled(Col)``;
type CarouselColumnProps = {
	xl?: number;
	lg?: number;
	md?: number;
	sm?: number;
	xs?: number;
	className?: string;
	children?: React.ReactNode;
};
export const CarouselColumn: React.FC<CarouselColumnProps> = ({ children, ...rest }) => {
	return <StyledColumn {...rest}>{children}</StyledColumn>;
};

import React, { FC, useMemo } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { BodyText, H1, H2, IntroText, NormalLabel, TinyLabel } from '../ui/core/Typography/typography.styles';
import { ButtonGroup } from '../ui/core/Buttons/Buttons';
import { media } from '../ui/utils/media';
import Button from '../models/strapi/Button';
import { processCmsImageUrl } from '../utils/urls';

const PresentationContainer = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

const Background = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	bottom: 0;
	border-radius: 8px;
	overflow: hidden;

	${media.tabletLandscapeUp} {
		height: 110%;
		width: 110%;
	}
`;

const ContentContainer = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	z-index: 3;
	gap: 6px;
	padding: 12px 16px;

	${media.tabletPortraitUp} {
		padding: 12px 20px;
		gap: 12px;
	}

	${media.tabletLandscapeUp} {
		padding: 12px 30px;
		gap: 12px;
	}

	${media.desktopUp} {
		padding: 12px 42px;
		gap: 24px;
	}

	${media.desktopLargeUp} {
		padding: 12px 56px;
	}

	${media.desktopXLargeUp} {
		padding: 12px 74px;
	}

	h1,
	p {
		margin: 0;
	}
`;

const LabelContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
`;

const IconContainer = styled.div`
	position: relative;
	width: 16px;
	height: 16px;

	${media.tabletLandscapeUp} {
		width: 20px;
		height: 20px;
	}
`;

const TextContainer = styled.div`
	display: -webkit-box;
	-webkit-line-clamp: 2; /*define how many line you want to show */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;

	${media.tabletLandscapeUp} {
		-webkit-line-clamp: 4; /*define how many line you want to show */
	}

	p {
		color: ${({ theme }) => theme.colors.tealPlus60};
	}
`;

const ButtonContainer = styled.div`
	display: none;
	flex-direction: column;
	gap: 16px;

	${media.tabletLandscapeUp} {
		display: flex;
		flex-direction: row;
		gap: 32px;
	}
`;

type PresentationProps = {
	label?: string;
	title?: string;
	description?: string;
	url?: string;
	button?: Button;
	iconUrl?: string;
};

const PresentationComponent: FC<PresentationProps> = ({ label, title, description, button, url, iconUrl }) => {
	const buttonComponent = useMemo(() => {
		if (!button && !!url) return null;
		if (button) {
			return <ButtonGroup type={button.type} text={button.text} url={url || button.url} />;
		}
		return <ButtonGroup type={'secondary'} text={'Skoða safn'} url={url} />;
	}, [url, button]);

	return (
		<PresentationContainer>
			<Background>
				<Image layout="fill" objectFit="cover" src={'/icons/presenter.svg'} />
			</Background>
			<ContentContainer>
				<LabelContainer>
					{!!iconUrl && (
						<IconContainer>
							<Image layout="fill" objectFit="contain" src={processCmsImageUrl(iconUrl)} />
						</IconContainer>
					)}
					<NormalLabel>{label}</NormalLabel>
				</LabelContainer>
				<H1>{title}</H1>
				<TextContainer>
					<IntroText>{description}</IntroText>
				</TextContainer>
				<ButtonContainer>{buttonComponent}</ButtonContainer>
			</ContentContainer>
		</PresentationContainer>
	);
};

export default PresentationComponent;

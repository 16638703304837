import styled from 'styled-components';
import { media } from '../../ui/utils/media';

export const FAQContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: center;
	align-items: center;
	padding: 0px;
	z-index: 2;

	${media.tabletLandscapeUp} {
		padding: 64px 0px;
	}
`;

export const BackgroundMask = styled.div`
	display: block;
	overflow: hidden;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
	margin: 0px -5%;
	background: ${({ theme }) => theme.colors.teal};

	${media.tabletLandscapeUp} {
		display: none;
	}
`;

export const FlexContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	max-width: 1440px;
	z-index: 3;
	padding: 0px;

	${media.tabletLandscapeUp} {
		padding: 64px 96px;
	}

	${media.desktopUp} {
		gap: 96px;
	}
`;

export const FaqSection = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 64px;
	padding: 64px 0px;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.teal};

	${media.tabletLandscapeUp} {
		padding: 96px;
	}

	${media.desktopUp} {
		gap: 96px;
	}
`;

export const HeadSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px 96px;
	gap: 16px;
	width: 100%;
	max-width: 640px;
	text-align: center;

	h2,
	p {
		margin: 0;
	}
`;

export const QuestionSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;
`;

export const QuestionItem = styled.div<{ isOpen: boolean }>`
	display: flex;
	flex-direction: column;
	height: auto;
	max-height: ${({ isOpen }) => (isOpen ? 350 : 50)}px;
	border-bottom: 2px solid ${({ theme }) => theme.colors.tealPlus40};
	overflow: hidden;
	cursor: pointer;

	p {
		margin: 0;
	}

	${media.tabletLandscapeUp} {
		max-height: ${({ isOpen }) => (isOpen ? 350 : 50)}px;
	}

	transition: 0.5s linear;
`;

export const QuestionHead = styled.div`
	display: flex;
	position: relative;
	align-items: flex-start;
`;

export const IconContainer = styled.div<{ isOpen: boolean }>`
	position: absolute;
	right: 0;
	top: 0;

	svg {
		transform: rotateX(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
		transition: 0.5s ease;
	}
`;

export const QuestionAnswer = styled.div<{ isOpen: boolean }>`
	display: flex;
	align-items: flex-start;
	width: 80%;
	margin: 25px 0px;

	visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
	opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

	transition: 0.5s ease;
`;

import styled from 'styled-components';
import { media } from '../../ui/utils/media';
import { breakpoints } from '../../styles/breakpoints';
import { sharedFontStyles } from '../../ui/core/Typography/typography.styles';

export const CarouselContainer = styled.div`
	margin-bottom: 15px;
	position: relative;

	@media ${breakpoints.md} {
		margin-bottom: 30px;
	}
`;

export const TitleContainer = styled.div<{ withPresentation?: boolean }>`
	display: flex;
	align-items: center;
	visibility: ${({ withPresentation }) => (withPresentation ? 'hidden' : 'visible')};
	min-width: 0;
	margin-bottom: 5px;
	${media.tabletPortraitUp} {
		margin-bottom: 10px;
	}
`;

export const SeeAllLink = styled.a`
	cursor: pointer;
	display: flex;
	align-items: center;
	&:hover,
	:active {
		text-decoration: none;
		color: ${({ theme }) => theme.colors.white};
	}
`;

export const PresentationSection = styled.div`
	display: block;
	flex: 0 0 50%;

	${media.tabletPortraitUp} {
		flex: 0 0 33.333333333%;
	}
`;

export const TopListTextContainer = styled.div`
	display: inline-block;
	flex: 0 0 15%;
	height: 100%;

	${media.tabletPortraitUp} {
		flex: 0 0 10%;
	}
`;

export const TopListText = styled.p`
	${sharedFontStyles}
	margin: 0;
	position: relative;
	line-height: 0.73em;
	font-size: clamp(5.875rem, 2.0559rem + 14.6682vw, 27.125rem);
	font-weight: 900;
	letter-spacing: -0.12em;
	text-align: right;
	user-select: none;

	background: ${({ theme }) => theme.misc.textGradient};
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	color: ${({ theme }) => theme.colors.tealMinus40};
`;

export const ContentContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;

export const ViewPort = styled.div`
	position: relative;
	height: 100%;
`;

export const ViewPortContent = styled.div`
	display: flex;
	height: 100%;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-webkit-tap-highlight-color: transparent;
	will-change: transform;
`;
